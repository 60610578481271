import React from 'react';

import Typography from '../../_ui/_blocks/Typography/Typography';
import ButtonLink from '../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';

interface Props {
    closeBasket: () => void;
}

const BasketEmpty = ({ closeBasket }: Props) => (
    <div className="text-center p-10 px-300 bg-layers-white">
        <Typography as="h2" typeset="title" size="600" className="block mt-200">
            Empty basket
        </Typography>
        <Typography as="p" typeset="subheading" size="080" color="quiet" className="mt-100">
            There’s nothing in your basket. Find your ideal treatment to get started.
        </Typography>
        <ButtonLink
            href="/treatments"
            title="View all treatments"
            className="flex items-center justify-center mt-100"
            onClick={closeBasket}
        >
            View all treatments
        </ButtonLink>
        <ButtonLink
            variant="tertiary"
            href="/conditions"
            title="View all conditions"
            className="flex items-center justify-center mt-100"
            onClick={closeBasket}
        >
            View all conditions
        </ButtonLink>
    </div>
);

export default BasketEmpty;
