import sanityImageUrlBuilder from '@sanity/image-url'
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

import { dataset, projectId } from './env'


const imageUrlBuilder = sanityImageUrlBuilder({
    projectId: projectId || '',
    dataset: dataset || '',
});

/**
 * Sanity function to generate an image URL.
 * @see https://www.sanity.io/docs/image-url
 */
export const urlForImage = (source: SanityImageSource) => (
    imageUrlBuilder.image(source)
);
