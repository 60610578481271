"use client";

import React from 'react';
import dayjs from '@/lib/dayjs/dayjs';

import useSWR from 'swr';
import { ImageFields } from '../../../../lib/sanity/queries/image.query';
import { getResponsiblePharmacist } from './actions/getResponsiblePharmacist';

import Typography from '../../../_ui/_blocks/Typography/Typography';
import Avatar from '../../../Avatar/Avatar';

export interface ResponsiblePharmacist {
    firstName: string;
    lastName: string;
    slug: {
        current: string;
    };
    profileImage: ImageFields;
}

/**
 * Displays the responsible Pharmacist Found in the footer.
 * @param props
 * @returns {JSX.Element}
 */
const ResponsiblePharmacist = (): JSX.Element | null => {
    // Gets the responsible pharmacist for the footer.
    const {
        isLoading,
        data,
    } = useSWR(
        `responsible-pharmacist`,
        async () => getResponsiblePharmacist(),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            refreshWhenHidden: false,
            refreshWhenOffline: false,
            revalidateIfStale: false,
            revalidateOnMount: true,
        }
    );


    const responsiblePharmacist =  data?.responsiblePharmacist;

    // Get the current date in format.
    const today = dayjs().local().format('YYYY-MM-DD');

    // If it's not the weekend and the time is between 08:30 and 14:00.
    const isDisplayTimeSlot = dayjs().local().isoWeekday() <= 5 && dayjs().local().isBetween(`${today} 08:30:00`, `${today} 17:00:00`);

    // If it's the wrong time to display the Responsible Pharmacist or there is not one set, don't display.
    if (!isDisplayTimeSlot) {
        return null;
    }

    if (isLoading || !responsiblePharmacist) {
        return null;
    }

    const ResponsiblePharmacistName = `${responsiblePharmacist.firstName} ${responsiblePharmacist.lastName}`;

    return (
        <div className="md:mt-200 lg:mt-0">
            <Typography as="h5" typeset="subtitle" size="200" color="layers-highlight">
                Today's responsible Pharmacist
            </Typography>
            <a
                href={`/about/meet-the-team/${responsiblePharmacist.slug.current}`}
                className="flex items-center gap-050 mt-025 type-090 lg:mt-100"
            >
                <Avatar
                    src={responsiblePharmacist.profileImage?.asset?.url}
                    alt={responsiblePharmacist.profileImage?.asset?.altText || ResponsiblePharmacistName}
                    size="small"
                    fallback="/images/profile.svg"
                />
                {ResponsiblePharmacistName}
            </a>
        </div>
    );
};

export default ResponsiblePharmacist;
