import React, { ReactNode, useMemo, useRef, useState } from 'react';
import get from 'lodash.get';

import { useDidUpdateEffect } from '@/hooks/useDidUpdateEffect';
import { useJoiFormContext } from '../JoiForm';
import usePrevious from '../../../../../../hooks/usePrevious';

import Error from '../../Validation/Error/Error';

import styles from './JoiFormInputWrap.module.css';

interface Props {
    /** Name of the property that we're validating against. */
    name: string;
    children: ReactNode;
    validateOnChange?: boolean;
    hideErrorMessage?: boolean;
    className?: string;
    onlyOnSubmit?: boolean;
    showInputErrors?: boolean;
    showIcon?: boolean | undefined;
}

/**
 * Wrap this around your joi form input. to show validation error messages.
 * @param {props} props
 */
// TODO: rename hideErrorMessage to showErrors
// TODO: rename onlyOnSubmit
const JoiFormInputWrap = ({
    name,
    className = '',
    children = undefined,
    validateOnChange = false,
    hideErrorMessage = false,
    showInputErrors = true,
    onlyOnSubmit = false,
    showIcon=true
}: Props) => {
    const { errors, formState, validate, formDirty } = useJoiFormContext();
    const [isDirty, setIsDirty] = useState(false); // gets changed by the input value tracking

    const inputValue = get(formState, name);
    const prevInputValue = usePrevious(inputValue);
    const inputHasChanged = useRef<boolean>(false);

    useDidUpdateEffect(() => {
        if (inputValue !== prevInputValue && validateOnChange) {
            if (!isDirty) setIsDirty(true);
            validate(name);
            inputHasChanged.current = true;
        }
    }, [inputValue, validate, validateOnChange]); // eslint-disable-line

    const err = useMemo(() => {
        if (onlyOnSubmit && !formDirty) return false; // error will not return if onlyOnSubmit
        if (!formDirty && !isDirty) return false; // error will not show if the value hasnt been change OR if the form hasnt tried to be submitted
        if (!errors || !errors.length) return false;

        return errors.find((er: { context: { key: string }; path: string[] }) => {
            if (er.context.key === name) return true;
            if (Array.isArray(er.path)) {
                if (er.path.includes(name)) return true;
                if (er.path.join('.') === name) return true;
            }

            return false;
        });
    }, [errors, formDirty, isDirty, name, onlyOnSubmit]);

    let errorClassName = '';

    if (err) {
        errorClassName += `${showInputErrors ? styles.fieldInvalid : ''}`;
    }

    if (!err && inputHasChanged.current) {
        errorClassName += styles.fieldValid;
    }

    return (
        <div className={`${className} ${errorClassName} space-y-025 ${!hideErrorMessage && err ? '_js-joi-error' : ''}`}>
            {children}
            {!hideErrorMessage && err ? <Error showIcon={showIcon}>{err.message.replace(/"/g, '')}</Error> : null}
        </div>
    );
};

export default JoiFormInputWrap;
