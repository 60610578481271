import React from 'react';

import SvgWrapper from './SvgWrapper';

type Props = React.ComponentProps<typeof SvgWrapper>;

const ChevronRight = (props: Props) => (
    <SvgWrapper {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.4393 10.4393C17.0251 9.85355 17.9749 9.85355 18.5607 10.4393L30.8107 22.6893C31.3964 23.2751 31.3964 24.2249 30.8107 24.8107L18.5607 37.0607C17.9749 37.6464 17.0251 37.6464 16.4393 37.0607C15.8536 36.4749 15.8536 35.5251 16.4393 34.9393L27.6287 23.75L16.4393 12.5607C15.8536 11.9749 15.8536 11.0251 16.4393 10.4393Z" />
    </SvgWrapper>
);

export default ChevronRight;
