import React, { Fragment, useState, useEffect, useMemo } from 'react';

import { isDecimal } from '../../../helpers/maths';
import useHandleKeyDown from '../../Treatment/TreatmentSelector/QuantitySelector/TimedQuantitySelector/hooks/useHandleKeyDown/useHandleKeyDown';
import { BasketItem } from '../../../types/api/basket/BasketItem';
import formatPrice from '../../../helpers/formatPrice';

import NumberInput from '../../_ui/_blocks/Form/Inputs/NumberInput/NumberInput';
import Typography from '../../_ui/_blocks/Typography/Typography';
import Error from '../../_ui/_blocks/Form/Validation/Error/Error';

export interface Props {
    id: string;
    item: BasketItem;
    onChange: (e: any) => void;
    limit?: number;
}

const TimedSelector = ({ id, item, onChange, limit = 1000 }: Props) => {
    const { isDirty, handleKeyDown } = useHandleKeyDown();

    const [numberOfPills, setNumberOfPills] = useState<number | string>(item.quantity > limit ? limit : item.quantity);
    const unitCost = item.quantities[0].unit_cost;

    const onPillChange = (pills: number | string) => {
        if (!pills) {
            setNumberOfPills('');
            return;
        }

        setNumberOfPills(parseFloat(pills.toString()));
    };

    const calculatedPrice = numberOfPills ? unitCost * +numberOfPills : 0;

    const error = useMemo(() => {
        if (isDecimal(+numberOfPills)) return 'Quantity cannot be a decimal number.';
        if (!numberOfPills) return 'Please enter a valid quantity.';
        if ((numberOfPills as number) > limit) return `No more than ${limit} pills can be purchased.`;
        return '';
    }, [numberOfPills, limit]);

    useEffect(() => {
        const quantity = !error ? numberOfPills : null;
        const price = calculatedPrice;

        onChange({
            ...item,
            quantity,
            unit_cost: unitCost,
            price,
            customer_label: null,
        });
    }, [error, numberOfPills, unitCost]);

    return (
        <Fragment>
            <NumberInput
                id={id}
                step="1"
                min="1"
                max={limit}
                isDirty={isDirty}
                isValid={!error}
                value={numberOfPills}
                onKeyDown={handleKeyDown}
                onChange={(e) => onPillChange(e.currentTarget.value)}
                data-testid="timed-selector"
                className="w-full"
            />
            {error ? (
                <Error className="mt-050">{error}</Error>
            ) : (
                <Typography as="div" size="080" color="price" className="text-right mt-025">
                    Price: <span className="type-heading type-080">{formatPrice(calculatedPrice)}</span>
                </Typography>
            )}
        </Fragment>
    );
};

export default TimedSelector;
