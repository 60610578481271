import { useBasketContext, formatBasketConsultationIds } from '../basketContext';
import useDataApi from '../../../../hooks/useDataApi';
import basketService from '../../../services/basket.service';
import { removeItemFromArray } from '../../../../helpers/manipulateArray';
import dispatchGoogleAnalyticsEvent, { gaEvents, gaFormatters } from '../../../../modules/analytics/google/dispatchGoogleAnalyticsEvent';
import ProductTypes from '../../../../types/api/products/ProductTypes';
import { bloomreachPixelSendAddToCartEvent } from '../../../../modules/analytics/bloomreach/BloomreachPixel/sendBloomreachEventTrackingEvent';
import { ConsultationAnalyticsData } from '../../../../app/(consultation)/[dynamicRoute]/consultation/_components/Context/ConsultationDataContext';

export interface AddToBasketData {
    prodId: number;
    type: ProductTypes;
    reference: number;
    treatment: string;
    label: string;
    quantity: number;
    total: number;
    product_name: string; // For analytics - parent treatment name
    is_default_variant: boolean; // For analytics
    order_type: string; // For analytics - provided by the consultation
    condition_name: string; // For analytics
    unit_cost: number; // For analytics
    boltOn?: number; // The value is the id of the treatment the bol on relates to if the product being added is a bolt on.
    image: string | null;
    description: string | null;
    recChoice: ConsultationAnalyticsData['recChoice'];
    patientType: ConsultationAnalyticsData['patientType'];
}

const useManageBasketItems = () => {
    // @ts-expect-error
    const { basket, setBasket, basketUuid } = useBasketContext();

    /**
     * Provides polling function that is added to the usePollingHook further down. Also provides
     * error, loading and response states.
     */
    const [{ isLoading: isUpdating, error: updateBasketError }, updateBasket] = useDataApi(basketService.updateBasket);

    /**
     * Calls the createBasket API with response data.
     */
    const [{ isLoading: isCreating, isError: createBasketError }, createBasket] = useDataApi(basketService.createBasket);

    /**
     * Removes an item from the basket by item ID.
     * @param {number} id - ID of the basket item to remove.
     */
    const updateItems = async (items: number) => {
        const postData = { action: 'UPDATE_ITEMS', items };
        const response = await updateBasket(basketUuid, postData);

        if (!response) return null;

        /**
         * Because treatment was changed to treatmentId, we need to loop through a basket and treatmentId
         * basket.item.consultation.treatmentId.
         */
        let newBasket = response.data && response.data.data ? response.data.data : { items: [] };
        newBasket = formatBasketConsultationIds(newBasket);

        setBasket(newBasket);
        return response;
    };

    /**
     * Removes an item from the current basket state before waiting for response.
     * @param {array} ids - array of basket item IDs to remove.
     */
    const removeBasketItemsFromState = (ids: { id: number }[]) => {
        const basketClone = { ...basket };

        ids.forEach(({ id }) => {
            const basketItem = basketClone.items.find((item: { id: number }) => item.id === id);

            if (!basketItem) return;

            const itemPrice = basketItem.total;

            basketClone.items = removeItemFromArray(basketClone.items, id, 'id');
            basketClone.total = basket.total - itemPrice;
        });

        setBasket(basketClone);
    };

    /**
     * Removes items from the basket by item ID.
     * @param {array} id - Array of IDs to remove from the basket [{ id: id }].
     */
    const deleteItems = async (ids: { id: number }[]) => {
        const postData = { action: 'DELETE_ITEMS', items: ids };
        removeBasketItemsFromState(ids);

        const response = await updateBasket(basketUuid, postData);
        if (!response) return null;

        setBasket(response.data && response.data.data ? response.data.data : { items: [] });
        return response;
    };

    /**
     * Adds an item to the current active basket.
     * @param {object} item - Item to be added.
     */
    const addItem = async (item: AddToBasketData) => {
        let postData: { items: (typeof item)[]; action: string; total: number } = { items: [item], action: '', total: 0 };
        let response;

        if (basketUuid) {
            postData = { ...postData, action: 'ADD_ITEMS' };
            response = await updateBasket(basketUuid, postData);
        } else {
            postData = { ...postData, total: postData.items[0].total };
            response = await createBasket(postData);
        }

        if (!response) {
            return null;
        }

        /**
         * Because treatment was changed to treatmentId, we need to loop through a basket and treatmentId
         * basket.item.consultation.treatmentId.
         */
        let newBasket = response.data.data;
        newBasket = formatBasketConsultationIds(newBasket);

        setBasket(newBasket);

        // TODO: fix types
        // @ts-ignore
        dispatchGoogleAnalyticsEvent(gaEvents.AddToBasket, gaFormatters.addToBasket(item));

        bloomreachPixelSendAddToCartEvent({
            prod_id: item.prodId.toString(),
            sku: item.reference.toString(),
            quantity: item.quantity,
        });

        return response;
    };

    /**
     * Takes an array of object basket items and loops through each of them sequentially to add them to the
     * basket. Created this function as looping through and using addItem above doesn't take into account
     * that the state may not have updated before the next add to basket call is made. Since the state may
     * not be up to date, it might not have access to the existing basket UUID and so could create multiple
     * new baskets.
     */
    const addMultipleItems = async (items: AddToBasketData[]) => {
        let existingBasketUUID = basketUuid || null;
        const results = [];

        const create = async (item: AddToBasketData) => {
            const result = await createBasket({
                items: [item],
                total: item.total,
            });

            // Store the basket UUID for future updates now that one has been created.
            existingBasketUUID = result.data.data.uuid;
            return result;
        };

        const update = async (item: AddToBasketData) => updateBasket(existingBasketUUID, {
            items: [item],
            action: 'ADD_ITEMS',
        });

        // Used a for loop as its slightly more efficient than a forEach loop and we can use await inside it.
        // Didn't use a promise.allSettled as they will all run concurrently and we want them to run
        // sequentially.
        for (let i = 0; i < items.length; i += 1) {
            const item = items[i];

            try {
                dispatchGoogleAnalyticsEvent(
                    gaEvents.AddToBasket,

                    // TODO: fix types
                    // @ts-ignore
                    gaFormatters.addToBasket(item)
                );

                bloomreachPixelSendAddToCartEvent({
                    prod_id: item.prodId.toString(),
                    sku: item.reference.toString(),
                    quantity: item.quantity,
                });

                // If there is an existing basket UUID, update the basket, otherwise create a new basket.
                const result = existingBasketUUID
                    ? await update(item) // eslint-disable-line no-await-in-loop
                    : await create(item); // eslint-disable-line no-await-in-loop
                results.push({ status: 'resolved', value: result });
            } catch (error) {
                results.push({ status: 'rejected', reason: error });
            }
        }

        let latestBasket = results.at(-1)?.value.data.data;
        latestBasket = formatBasketConsultationIds(latestBasket);
        setBasket(latestBasket);

        return results;
    };

    return {
        updateItems,
        deleteItems,
        addItem,
        addMultipleItems,

        error: updateBasketError || createBasketError,
        isLoading: isUpdating || isCreating,
    };
};

export default useManageBasketItems;
