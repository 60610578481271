"use client";

// this is needed for headless ui to work here
import React from 'react';
import dynamic from 'next/dynamic';

import { useConfirmationContext } from '../../../data/context/confirmationContext';

import Modal from '../Modal/Modal';

const ConfirmationAlert = dynamic(() => import('./ConfirmationTypes/ConfirmationAlert'));

/** We have a few confirmation styles we need with different markups, here we use a component index and load a new file based upon type passed in */
const Confirmation = () => {
    const { isConfirmationOpen, confirmationAttributes, closeConfirmation, reset } = useConfirmationContext();

    return (
        <Modal show={isConfirmationOpen} onClose={closeConfirmation} onClosed={reset}>
            <ConfirmationAlert {...confirmationAttributes} type={confirmationAttributes.type} />
        </Modal>
    );
};

export default Confirmation;
