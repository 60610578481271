/**
 * helper to help remove an item from an array.
 * example:
 * const abc = [{id: 'a'}, {id: 'b'}, {id: 'c'}];
 * removeItemFromArray(abc, 'b'); // [{id: 'a'}, {id: 'c'}];
 * @param {*} arr - the array to remove the item for
 * @param {*} id - id of the item to find
 * @param {*} idKeyName - name of the property that is the id - defaults to 'id'
 */
export const removeItemFromArray = (arr, id, idKeyName = 'id') => {
    if (!arr) return arr;
    const clonedArr = [...arr];

    const indexFound = clonedArr.findIndex((item) => item[idKeyName] === id);

    clonedArr.splice(indexFound, 1);

    return clonedArr;
};

/**
 * Finds and updates an item in the provided array.
 * @param {array} array - The array to update the item in.
 * @param {any} item - Content to replace in the array.
 * @param {function} findIndexFunc - Function used to find the item in an array (Array.findIndex()).
 * @return {array} - The updated array or original array if the item was not found.
 */
export const updateItemInArray = (array, item, findIndexFunc) => {
    const index = array.findIndex(findIndexFunc);

    if (index === -1) return array;

    const arrayClone = [...array];
    arrayClone.splice(index, 1, item);

    return arrayClone;
};
