import countries from '../../data/json/countries.json';

/**
 * Gets the full name of a country using it's code.
 * @param {string} code - The country code e.g. GB.
 */
export const formatCountry = (code) => countries[code] || `Country not found - code: ${code}`;

const defaultFieldLoop = ['businessName', 'address1', 'address2', 'city', 'county', 'postcode'];

/**
 * Accept a group of address fields and convert them into a formatted address string
 * @param {any} addressObj - the string values to be joined
 * @param {array[string]} fieldsToLoop - array of fields to loop through in order of the array passed in
 * @param {string} joinFormat - The format of the separation between address fields.
 */
export const formatAddress = (addressObj, fieldsToLoop = defaultFieldLoop, joinFormat = ',\n') => {
    if (!addressObj) {
        return undefined;
    }

    const addressArr = [];

    fieldsToLoop.forEach((k) => {
        if (addressObj[k] && addressObj[k] !== '') {
            if (k === 'country') {
                addressArr.push(formatCountry(addressObj[k]));
                return;
            }

            addressArr.push(addressObj[k]);
        }
    });

    const addressStr = addressArr.join(joinFormat);

    return addressStr;
};
