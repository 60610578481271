'use client';

import React, { Fragment } from 'react';
import { usePathname } from 'next/navigation';

import { useBasketContext } from '@/data/context/basketContext/basketContext';
import { BasketItem as BasketItemType } from '../../types/api/basket/BasketItem';

import Flyout from '../_ui/Flyout/Flyout';
import BasketSummary from './BasketPreview/BasketSummary/BasketSummary';
import Loading from '../_ui/_blocks/Loading/Loading';
import BasketEmpty from './BasketEmpty/BasketEmpty';
import BasketItem from './BasketList/BasketItem/BasketItem';
import Icon from '../_ui/_blocks/Icon/Icon';
import Flash from '../FlashMessage/Flash/Flash';
import Link from '../NextJs/Link/Link';
import IsBasketProcessing from './IsBasketProcessing/IsBasketProcessing';

interface Props {
    minimumDeliveryCost: number;
}

const Basket = ({ minimumDeliveryCost, }: Props) => {
    const pathname = usePathname();

    const isBasketLocked = pathname?.includes('/checkout') || false;

    // @ts-expect-error
    const { basket, basketIsOpen, setBasketIsOpen, initialLoadComplete } = useBasketContext();

    const items: BasketItemType[] = basket && basket.items ? basket.items : [];

    const basketSubtotal = basket?.total || 0;

    const handleBasketClose = () => setBasketIsOpen(false);

    const title = isBasketLocked ? (
        <span className="flex gap-050 items-center">
            <Icon icon="lock-light" alt="Basket locked" /> Your basket
        </span>
    ) : 'Your basket';

    return (
        <Flyout isOpen={basketIsOpen} onClose={handleBasketClose} title={title}>
            <div data-testid="saved-basket-preview" className="flex flex-col h-full justify-center">
                <IsBasketProcessing>
                    {isBasketLocked ? (
                        <div className="pt-100 px-100">
                            <Flash type="warning" iconPosition="start">
                                You're checking out, so your basket can no longer be changed. If you need to make
                                changes, leave the checkout and come back when you're ready.
                                <Link href="/" className="block mt-050 mr-auto type-link">Back to homepage</Link>
                            </Flash>
                        </div>
                    ) : null}
                    <Loading isLoading={!initialLoadComplete} iconClassName="flex-grow">
                        {items.length ? (
                            <Fragment>
                                <ul className="p-100 space-y-100 flex-grow overflow-auto bg-layers-canvas">
                                    {items.map((item) => (
                                        <BasketItem key={item.id} item={item} isLocked={isBasketLocked} />
                                    ))}
                                </ul>
                                <BasketSummary
                                    minimumDeliveryCost={minimumDeliveryCost}
                                    basketSubtotal={basketSubtotal}
                                    isLocked={isBasketLocked}
                                    onClose={handleBasketClose}
                                />
                            </Fragment>
                        ) : (
                            <BasketEmpty
                                closeBasket={handleBasketClose}
                            />
                        )}
                    </Loading>
                </IsBasketProcessing>
            </div>
        </Flyout>
    );
};

export default Basket;
