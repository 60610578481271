import React, { useState } from 'react';

import { useBasketContext } from '../../../../data/context/basketContext/basketContext';
import { API_ERROR_MESSAGE } from '../../../../constants';

import ButtonBusy from '../../../_ui/_blocks/Buttons/ButtonBusy/ButtonBusy';
import Card from '../../../_ui/_blocks/Card/Card';
import Typography from '../../../_ui/_blocks/Typography/Typography';
import Button from '../../../_ui/_blocks/Buttons/Button/Button';
import Attention from '../../../_ui/_blocks/Attention/Attention';

export interface Props {
    onClose: (args?: any) => void;
}

const SaveBasketConfirmation = ({ onClose }: Props) => {
    const [errorSavingBasket, setErrorSavingBasket] = useState(false);
    // @ts-expect-error
    const { saveBasket, loading, setBasketIsOpen } = useBasketContext();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!(await saveBasket())) {
            setErrorSavingBasket(true);
            return;
        }

        setBasketIsOpen(false);
        dispatchEvent(new Event('basket_saved'));
    };

    return (
        <Card as="form" onSubmit={handleSubmit} className="space-y-100">
            <Typography as="p" color="quiet">
                This will empty your basket, but will save the contents to your account so you can complete your order later. Would you like
                to save this basket?
            </Typography>
            {errorSavingBasket ? (
                <Attention type="error" heading="Basket could not be saved" headingSize="090">
                    <Typography as="p" size="090">
                        {API_ERROR_MESSAGE}
                    </Typography>
                </Attention>
            ) : null}
            <div className="flex gap-100 justify-end">
                <Button variant="secondary" className="flex-shrink-0" title="Cancel the saving of your basket" onClick={onClose}>
                    Cancel
                </Button>
                <ButtonBusy type="submit" className="flex-grow" loading={loading} title="Saving your basket" size="small">
                    Yes, save it
                </ButtonBusy>
            </div>
        </Card>
    );
};

export default SaveBasketConfirmation;
