import { useRef, useEffect } from 'react';

/**
 * This is a wrapper for use effect so that the use effect isnt run on initial render.
 * Useful for listening to user changes but you dont want to fire an event or callback
 * until the user actually interacts with it.
 * @param {function} callback - function
 * @param {array} dependencies array
 */
export const useDidUpdateEffect = (callback, dependencies = []) => {
    const didMountRef = useRef(false);
    const cbRef = useRef(undefined);

    useEffect(() => {
        if (didMountRef.current) {
            cbRef.current = callback();
        } else {
            didMountRef.current = true;
        }

        return () => {
            if (cbRef.current) {
                cbRef.current();
            }
        };
    }, dependencies); // eslint-disable-line
};
