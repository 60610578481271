'use client';

import { useEffect } from 'react';
import { isBrowser } from '../../config/config';
import { setCookie } from '../../helpers/cookie';

import { validateAffiliateCode } from './lib/validateAffiliateCode';
import { localStorageRemove, localStorageSet } from '../../helpers/localStorage';
import getQueryString from '../../helpers/getQueryString';

/**
 * Checks the url to see if we have any affiliate code , if we do it sends a validation request to the API.
 * We are currently running this in gatsby-browser.js
 * @returns {void}
 */
export const CheckAffiliateCode = () => {
    useEffect(() => {
        const check = async () => {
            localStorageRemove('affililate_check');

            if (isBrowser() === false) {
                return;
            }

            const code = getQueryString('affiliate');

            if (typeof code !== 'undefined') {
                const { isValid, token, daysTillExpire } = await validateAffiliateCode(code);

                if (isValid) {
                    setCookie('affiliate', token, daysTillExpire);
                }
            }

            /** Event Listened to in in useAffiliateCheckedHook.js */
            dispatchEvent(new Event('affiliate_checked'));
            localStorageSet('affililate_check', 'complete');
        }

        check();
    }, []);

    return null;
};

export default CheckAffiliateCode;
