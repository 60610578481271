import React, { Fragment } from 'react';

import useManageBasketItems from '../../../../../../data/context/basketContext/hooks/useManageBasketItems';
import { DeleteCardOverlay, DeleteCardOverlayTrigger } from '../../../../../_ui/Card/DeleteCardOverlay/DeleteCardOverlay';
import { BasketItem } from '../../../../../../types/api/basket/BasketItem';

import QuantityButton from '../QuantityButton/QuantityButton';
import Typography from '../../../../../_ui/_blocks/Typography/Typography';
import Tag from '../../../../../_ui/_blocks/Tag/Tag';
import ImageWithFallback from '../../../../../NextJs/ImageWithFallback/ImageWithFallback';

import styles from '../../BasketItem.module.css';

export interface Props {
    item: BasketItem;
    openQuantitySelector: (args?: any) => void;
    isLocked: boolean;
}

const QuantityUnavailableBasketItem = ({ item, openQuantitySelector, isLocked }: Props) => {
    const { deleteItems } = useManageBasketItems();

    return (
        <DeleteCardOverlay
            className={styles.item}
            title={item.treatment}
            description="Are you sure? This will also delete the consultation for this treatment."
            onDelete={() => deleteItems([{ id: item.id }])}
        >
            <Fragment>
                <div data-testid="quantity_outofstock_basket_item" className="flex items-start gap-100">
                    <ImageWithFallback
                        fallback="/images/pill.svg"
                        width={52}
                        height={52}
                        className={styles.image}
                        src={!Array.isArray(item.public) && item.public ? item.public.images.featuredUrl : null}
                        alt={item.treatment}
                    />
                    <div className="flex-grow">
                        <div className='flex justify-between items-start'>
                            <Tag size="medium" color="error">
                                Quantity unavailable
                            </Tag>
                            {!isLocked ? (
                                <DeleteCardOverlayTrigger title={`Remove ${item.treatment} from your basket`} />
                            ) : null}
                        </div>
                        <Typography as="h6" typeset="heading" size="080" className="mt-025">
                            {item.treatment}
                        </Typography>
                        <QuantityButton
                            isLocked={isLocked}
                            onClick={openQuantitySelector}
                            item={item}
                            className={isLocked ? undefined : 'mt-025'}
                        />
                    </div>
                </div>
            </Fragment>
        </DeleteCardOverlay>
    );
};

export default QuantityUnavailableBasketItem;
