import { useEffect, useState } from 'react';

import { basketProcessingEvent } from '../../../../data/services/basket.service';

/**
 * Adds a global event listener listening for the custom basketProcessingEvent. This event is triggered if a
 * basket request from the API returns a basket processing (409) response. See basket.service file for the
 * custom event dispatch.
 */
const useCatchBasketProcessingResponse = () => {
    // Stores if the basket has been flagged as processing.
    const [isBeingProcessed, setIsBeingProcessed] = useState(false);

    /* eslint-disable consistent-return */
    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const handleShowMessage = () => {
            setIsBeingProcessed(true);
        }

        window.addEventListener(basketProcessingEvent, handleShowMessage);

        return () => {
            window.removeEventListener(basketProcessingEvent, handleShowMessage);
        }
    }, []);
    /* eslint-enable consistent-return */

    return isBeingProcessed;
};

export default useCatchBasketProcessingResponse;
