import React from 'react';

import Icon from "../../../Icon/Icon"

interface Props {
    className?: string;
    children?: any;
    showIcon?: boolean;
}

const Error = ({ className = '', children, showIcon = true }: Props) => (
    <div className={`flex items-start gap-050 type-090 text-status-error-default ${className}`}>
        {showIcon ? (
            <Icon size="small" className="flex-shrink-0 mt-025" icon="error-status" alt="Error" />
        ) : null}
        {children ? <span dangerouslySetInnerHTML={{ __html: children }} /> : <span>This field is required</span>}
    </div>
);

export default Error;
