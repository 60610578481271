import React, { HTMLAttributes } from 'react';
import clx from 'classnames';

export enum CardVariants {
    default = '',
    bordered = 'card-bordered',
}

enum Padding {
    none = '',
    small = 'card-small',
    medium = 'card-medium',
    large = 'card-large',
}

enum Radius {
    default = '',
    large = 'card-radius-large',
}

enum BorderColors {
    default = '',
    accent = 'border-decorators-accent',
    error = 'border-status-error-default',
    strong = 'border-decorators-strong',
}

interface Props extends HTMLAttributes<HTMLElement> {
    as?: 'div' | 'article' | 'form' | 'li';
    padding?: keyof typeof Padding;
    variant?: keyof typeof CardVariants;
    radius?: keyof typeof Radius;
    borderColor?: keyof typeof BorderColors;
    className?: string;
    disableBoxShadow?: boolean;
}

const Card = ({
    as: Element = 'article',
    padding = 'medium',
    variant = 'default',
    radius = 'default',
    borderColor = 'default',
    disableBoxShadow,
    className,
    ...props
}: Props) => {
    const classNames = clx(
        className,
        'card',
        Padding[padding],
        CardVariants[variant],
        Radius[radius],
        BorderColors[borderColor],
        disableBoxShadow ? 'shadow-none' : ''
    );

    return <Element {...props} className={classNames} />;
};

export default Card;
