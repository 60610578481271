/* eslint-disable react/forbid-prop-types */
import React, { createContext, useState, useContext, useMemo, useCallback, useEffect, forwardRef } from 'react';
import Joi from 'joi';

import { selectorScrollTo } from '../../../../../helpers/scrollTo';

export const JoiFormContext = createContext({});

export interface Props {
    noAjax?: boolean;
    schema?: any;
    formState?: any;
    children?: React.ReactNode | ((args: { handleSubmit: (e: any) => void }) => React.ReactNode);
    onSubmit?: (formState: any) => void;
    options?: any;
    scrollToErrors?: boolean;
    useWithoutForm?: boolean;
    className?: string;
    [key: string]: any;
}

export const JoiForm = forwardRef<HTMLFormElement, Props>(
    (
        {
            schema,
            formState,
            children,
            onSubmit,
            options = {},
            scrollToErrors,
            noAjax,
            useWithoutForm,
            className,

            ...props
        }: Props,
        ref
    ) => {
        const [hasScrolled, setHasScrolled] = useState(true);
        const [formDirty, setFormDirty] = useState(false); // only gets set to dirty if we've tried to submit the form
        const [errors, setErrors] = useState<Joi.ValidationErrorItem[] | null>(null);

        const joiSchema = useMemo(() => Joi.object(schema), [schema]);

        const validate = useCallback(() => {
            const joiValidate = joiSchema.validate(formState || {}, { ...{ abortEarly: false }, ...options });

            setErrors(joiValidate.error ? joiValidate.error.details : null);

            return joiValidate;
        }, [formState]); // eslint-disable-line

        const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            setHasScrolled(false);
            const joiValidate = validate();

            if (!formDirty) setFormDirty(true);

            if (!joiValidate.error) {
                setErrors(null);

                /** If the form post is default this means we want to submit the form like a normal form on a normal website. */
                if (!noAjax) {
                    e.preventDefault();
                    if (onSubmit) {
                        onSubmit(formState);
                    }
                }
            } else {
                e.preventDefault();
            }
        };

        useEffect(() => {
            if (!hasScrolled && scrollToErrors && errors) {
                selectorScrollTo('._js-joi-error');
                setHasScrolled(true);
            }
        }, [errors, scrollToErrors, hasScrolled]);

        // TODO: We need to add a use memo to this to prevent re-renders (contextValueRefactor, Ticket: 862jfuy9w).
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        const value = {
            joiSchema,
            schema,
            formState,
            errors,
            validate,
            formDirty,
            // This is needed to mock the 'value' in Jest tests
            ...props,
        };

        // TODO: Refactor to listen to children to render out div or form. Check if children is a function or not,
        // then pass in handle submit
        if (useWithoutForm && typeof children === 'function') {
            return <JoiFormContext.Provider value={value}>{children({ handleSubmit })}</JoiFormContext.Provider>;
        }

        return (
            <form ref={ref} className={className} {...props} onSubmit={handleSubmit} data-testid="form">
                <JoiFormContext.Provider value={value}>{children as React.ReactNode}</JoiFormContext.Provider>
            </form>
        );
    }
);

JoiForm.defaultProps = {
    formState: null,
    scrollToErrors: true,
    noAjax: false,
    onSubmit: () => {
        //
    },
    className: '',
    children: undefined,
    useWithoutForm: undefined,
};

/**
 * Hook to be used in components that will allow us access to the values passed into the JoiForm Context provider above.
 */
export const useJoiFormContext = (): any => {
    const context = useContext(JoiFormContext);
    return context || {};
};
