"use client";

import React, { useLayoutEffect, useState } from 'react';

import { getCookie, setCookie } from '@/helpers/cookie';
import BloomreachAnalytics from '../../modules/analytics/bloomreach/BloomreachAnalytics';

import Typography from '../_ui/_blocks/Typography/Typography';
import Button from '../_ui/_blocks/Buttons/Button/Button';
import Link from '../NextJs/Link/Link';

import styles from './CookiesNotification.module.css';

const CookiesNotification = () => {
    const [hasConsented, setHasConsented] = useState(true);

    /** Want to delay rendering this for a while. */
    useLayoutEffect(() => {
        const cookieConsent = getCookie('cookie_consent');

        if (!cookieConsent) {
            setTimeout(() => {
                setHasConsented(!!cookieConsent);
            }, 3000);
        }
    }, []);

    if (hasConsented) return null;

    const handleGiveConsent = () => {
        setCookie('cookie_consent', true, 365);
        setHasConsented(true);
        BloomreachAnalytics.startExponea();
    };

    return (
        <div className={styles.cookieBanner} data-visual-test="transparent">
            <Typography as="p" size="090">
                We use website cookies to ensure that you receive the best experience. To learn more, read our{' '}
                <Link
                    className="font-700 hover:underline"
                    href="/cookie-policy"
                    title="View our cookie policy"
                    aria-label="View our cookie policy"
                >
                    Cookie Policy.
                </Link>
            </Typography>
            <Button
                type="button"
                variant="secondary"
                size="small"
                aria-label="Accept cookies"
                title="Accept cookies"
                className="flex-shrink-0"
                onClick={handleGiveConsent}
            >
                I Agree
            </Button>
        </div>
    );
};

export default CookiesNotification;
