import PropTypes from 'prop-types';

import { QUANTITY_TYPES } from '../../../constants';
import { questionTypes } from '../../Questions/questionComponentIndex';

const basketItemQuestion = PropTypes.shape({
    question: PropTypes.string.isRequired,
    type: questionTypes.isRequired,
    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
});

const basketItemConsultation = PropTypes.shape({
    type: PropTypes.oneOf(['EXPIRED', 'NEW', 'REFILL']),
    updated_at: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    medical: PropTypes.shape({
        weight: PropTypes.number,
        height: PropTypes.number,
        bmi: PropTypes.number,
        gender: PropTypes.string.isRequired,
    }),
    consultation: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(basketItemQuestion)]).isRequired,
    personal: PropTypes.arrayOf(basketItemQuestion),
    additional: PropTypes.arrayOf(basketItemQuestion),
    confirmation: PropTypes.arrayOf(basketItemQuestion),
    gp: PropTypes.object,
    quantity: PropTypes.number.isRequired,
    treatment: PropTypes.number.isRequired,
});

const basketItemShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    reference: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    treatment: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    consultation: basketItemConsultation,
    available: PropTypes.bool.isRequired,
    quantities: PropTypes.arrayOf(
        PropTypes.shape({
            quantity: PropTypes.number.isRequired,
            unit_cost: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    quantity_type: PropTypes.oneOf(Object.keys(QUANTITY_TYPES).map((type) => QUANTITY_TYPES[type])),
    quantity_change_question: PropTypes.number.isRequired,
});

export default basketItemShape;
