import React from 'react';
import clx from 'classnames';

import { urlForImage } from '../../lib/sanity/image';

import ImageWithFallback from '../NextJs/ImageWithFallback/ImageWithFallback';

import styles from './Avatar.module.css';

type ImageProps = React.ComponentProps<typeof ImageWithFallback>;

interface Props extends Omit<ImageProps, 'width' | 'height' | 'fallback'> {
    src: string | null | undefined;
    alt: string;
    size?: 'small' | 'medium' | 'large' | 'xlarge';
    fallback?: string;
}

const avatarSizes = {
    small: {
        width: 32,
        height: 32,
    },
    medium: {
        width: 48,
        height: 48,
    },
    large: {
        width: 64,
        height: 64,
    },
    xlarge: {
        width: 96,
        height: 96,
    }
}

/**
 * Displays an image of a user in a pill shape.
 */
const Avatar = ({ size = 'medium', className, src, fallback = '/images/pill.svg', ...rest }: Props) => {
    const classNames = clx(
        styles.avatar,
        styles[size],
        className,
    );

    const formattedImageSrc = src && src.includes('cdn.sanity.io')
        ? urlForImage(src).width(avatarSizes[size].width*2).height(avatarSizes[size].height*2).fit('crop').quality(80).url()
        : src;

    return (
        <ImageWithFallback
            {...rest}
            src={formattedImageSrc}
            className={classNames}
            width={avatarSizes[size].width}
            height={avatarSizes[size].height}
            fallback={fallback}
        />
    );
};

export default Avatar;
