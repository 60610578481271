import React from 'react';
import clx from 'classnames';

import IconCross from '../../_ui/_blocks/Icons/Navigation/IconCross';
import Typography from '../../_ui/_blocks/Typography/Typography';
import Icon from '../../_ui/_blocks/Icon/Icon';

import styles from './Flash.module.css';
import Cross from '../../_ui/_blocks/Icon/IconComponents/Cross';

type IconProps = React.ComponentProps<typeof Icon>;

const types = {
    info: styles.info,
    success: styles.success,
    warning: styles.warning,
    error: styles.error,
}

const icons = {
    info: 'info-status',
    success: 'success-status',
    warning: 'warning-status',
    error: 'error-status',
}

interface Props {
    type?: keyof typeof types;
    children?: React.ReactNode;
    onClose?: () => void;
    tight?: boolean;
    iconPosition?: 'center' | 'start';
    className?: string;
}

const Flash = ({ type = 'info', children, onClose, iconPosition = 'center', tight, className }: Props) => {
    const classNames = clx(
        styles.flash,
        types[type],
        tight ? styles.tight : '',
        iconPosition === 'center' ? 'items-center' : '',
        iconPosition === 'start' ? 'items-start' : '',
        className,
    );

    return (
        <div className={classNames}>
            <Icon icon={icons[type] as IconProps['icon']} alt={type} />
            <Typography as="p" size="080">{children}</Typography>
            {onClose ? (
                <button
                    type="button"
                    onClick={onClose}
                    title="Close message notification"
                >
                    <Cross />
                </button>
            ) : <span />}
        </div>
    );
};

export default Flash;
