"use client"

import React, { useCallback, useEffect, useRef } from 'react';
import dayjs from '@/lib/dayjs/dayjs';

import usePollingHook from '../../../../hooks/usePollinghook';
import { useConfirmationContext } from '../../../../data/context/confirmationContext';
import auth, { SESSION_FLASH_MESSAGE_KEY, SESSION_TIMEOUT_MESSAGE } from '../../../../modules/auth/auth';

import Countdown from '../../../_ui/Countdown';
import setFlashMessage from '../../../FlashMessage/helpers/setFlashMessage';
import Typography from '../../../_ui/_blocks/Typography/Typography';

/** Mins to be inactive before showing message. */
const timeoutMins = 10;

/**
 * Monitors user clicks and displays a message if the user hasnt interacted with the page.
 * @param {*} isLoggedIn
 * @param {*} logout
 */
const useSessionActivity = (isLoggedIn: boolean, logout: () => Promise<void>) => {
    const lastActive = useRef(dayjs().unix());

    const { openConfirmation, closeConfirmation, isConfirmationOpen } = useConfirmationContext();

    const forceLogout = async () => {
        auth.storeRedirectForAfterLogin(window.location.href);
        dispatchEvent(new Event('force_logout'));
        await logout();
        closeConfirmation();
        setFlashMessage(SESSION_TIMEOUT_MESSAGE, SESSION_FLASH_MESSAGE_KEY);
    };

    /**
     * Opens the confirmation window.
     */
    const displayEndSessionMessage = () => {
        const expireTime = dayjs().local().add(1, 'minute').valueOf();

        /*
         * Sets the last active to current time and requests new tokens.
         */
        const continueSession = () => {
            lastActive.current = dayjs().unix();
            auth.refreshToken();
            closeConfirmation();
        };

        openConfirmation({
            type: 'alert',
            severity: 'warning',
            heading: 'Session is about to expire',
            children: (
                <div className="space-y-050">
                    <Typography size="090" as="p" color="quiet">
                        Your browsing session is about to expire and you will be logged out of the application.
                    </Typography>
                    <Typography as="p">
                        <span>Your session will expire in: </span>
                        <strong>
                            <Countdown endTime={expireTime} onExpire={forceLogout} showUnits />
                        </strong>
                    </Typography>
                </div>
            ),
            onClose: continueSession,
            controls: {
                secondary: {
                    text: 'Log out',
                    title: 'Log out',
                    onClick: logout,
                },
                primary: {
                    text: 'Continue',
                    title: 'Continue',
                    onClick: continueSession,
                },
            },
        });
    };

    /**
     * Checks if the user has been inactive for 10 minutes.
     */
    const pollLastActive = useCallback(() => {
        if (isConfirmationOpen) return;

        const now = dayjs().unix();
        const difference = now - lastActive.current;

        // If it has been 10 minutes (600 seconds) since a click, display the confirmation message.
        if (difference >= timeoutMins * 60 && document.visibilityState === 'visible') displayEndSessionMessage();
    }, [lastActive.current, isLoggedIn, isConfirmationOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Polls the pollLastActive function if user is logged in.
     */
    // @ts-expect-error
    usePollingHook(isLoggedIn ? pollLastActive : null, 5000, [pollLastActive], false);

    /**
     * On a click, set the lastActive time to the current time.
     */
    const updateLastActive = () => {
        lastActive.current = dayjs().unix();
    };

    /**
     * Adds and removes click event listeners if the user is logged in.
     */
    useEffect(() => {
        if (!isLoggedIn) return () => {};

        /** if logged in is changed and we are logged in then we want to update the last active to now. */
        updateLastActive();
        const visibilityChange = () => {
            if (document.visibilityState === 'visible') updateLastActive();
        };

        document.body.addEventListener('click', updateLastActive);
        document.addEventListener(
            'visibilitychange',
            visibilityChange
        ); /** For when people load into the tab if they've been working with multiple tabs. */

        return () => {
            document.body.removeEventListener('click', updateLastActive);
            window.removeEventListener('visibilitychange', visibilityChange);
        };
    }, [isLoggedIn]);
};

export default useSessionActivity;
