'use client';

import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import clx from 'classnames';

import { useCookieWatcher } from '../../helpers/cookie';
import { DEFAULT_FLASH_MESSAGE_KEY, FLASH_EXPIRES_IN } from './config/config';

import Flash from './Flash/Flash';

import styles from './FlashMessage.module.css';

type FlashProps = React.ComponentProps<typeof Flash>;

interface Props extends Pick<FlashProps, 'type' | 'tight'> {
    cookieKey: string;
    className?: string;
}

const FlashMessage = ({ type = 'info', tight, cookieKey = DEFAULT_FLASH_MESSAGE_KEY, className }: Props) => {
    const [message, forceCookieRemove] = useCookieWatcher(cookieKey);

    const timeout = useRef<NodeJS.Timeout | undefined>();

    const classNames = clx(styles.flashMessage, className);

    useEffect(() => {
        timeout.current = setTimeout(() => forceCookieRemove(), FLASH_EXPIRES_IN * 60 * 1000);

        return () => {
            if (timeout.current) clearTimeout(timeout.current);
        };
    }, []);

    const handleClose = () => {
        forceCookieRemove();
    };

    if (!message) {
        return null;
    }

    return createPortal((
        <div className={classNames}>
            <Flash type={type} onClose={handleClose} tight={tight}>{message}</Flash>
        </div>
    ), document.body, 'flash-message');
};

export default FlashMessage;
