import React from 'react';

import SvgWrapper from './SvgWrapper';

type Props = React.ComponentProps<typeof SvgWrapper>;

const Cross = (props: Props) => (
    <SvgWrapper {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M0.93934 0.93934C1.52513 0.353553 2.47487 0.353553 3.06066 0.93934L24 21.8787L44.9393 0.93934C45.5251 0.353553 46.4749 0.353553 47.0607 0.93934C47.6464 1.52513 47.6464 2.47487 47.0607 3.06066L26.1213 24L47.0607 44.9393C47.6464 45.5251 47.6464 46.4749 47.0607 47.0607C46.4749 47.6464 45.5251 47.6464 44.9393 47.0607L24 26.1213L3.06066 47.0607C2.47487 47.6464 1.52513 47.6464 0.93934 47.0607C0.353553 46.4749 0.353553 45.5251 0.93934 44.9393L21.8787 24L0.93934 3.06066C0.353553 2.47487 0.353553 1.52513 0.93934 0.93934Z" />
    </SvgWrapper>
)

export default Cross;
