import React from 'react';

import useCatchBasketProcessingResponse from '../hooks/useCatchBasketProcessingResponse/useCatchBasketProcessingResponse';

import Attention from '../../_ui/_blocks/Attention/Attention';
import Link from '../../NextJs/Link/Link';

interface Props {
    children: React.ReactNode;
}

const IsBasketProcessing = ({ children }: Props) => {
    const basketCaughtBeingProcessed = useCatchBasketProcessingResponse();

    if (basketCaughtBeingProcessed) {
        return (
            <Attention heading="Your basket is being processed" type="warning" className="mx-100">
                <div className="space-y-050">
                    <p>
                        We are currently processing this basket to create your order. You should receive a
                        confirmation email shortly. We will email you with tracking information when your
                        order has been dispatched.
                    </p>
                    <p>
                        You can see your order in your <Link className="type-link" href="/account">dashboard</Link>. If
                        your order does not appear in the next 30 minutes please{' '}
                        <Link className="type-link" href="/contact-us">contact our support team</Link>.
                    </p>
                </div>
            </Attention>
        );
    }

    return children;
};

export default IsBasketProcessing;
